* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.APP-color { color: rgb(2, 2, 77) !important;}
.APP-background { background-color: rgba(239, 238, 238, 0.87) !important;}


/* width */
.w-100 { width: 100% !important; }
.w-80 { width: 80% !important; }
.w-95 { width: 95% !important; }
.w-50 { width: 50% !important; }
.w-25 { width: 25% !important; }

/* border */
.border { border: 1px solid lightgray;}
.border-darkblue { border: 1px solid gray; }
.b-r-10 { border-radius: 10px;}
.b-r-7 { border-radius: 7px;}
.b-5 {border-radius: 5px !important;}

/* Margin */
.m-0 { margin: 0px;}
.m-a-10 { margin: 10px; }
.m-a-20 { margin: 20px; }
.m-t-10 { margin-top: 10px !important; }
.m-b-10 { margin-bottom: 10px !important; }
.m-l-10 { margin-left: 10px !important;}
.m-r-10 { margin-right: 10px !important;}
.m-r-20 { margin-right: 20px !important;}
.m-t-15 { margin-top: 15px !important; }
.m-t-20 { margin-top: 20px !important; }

/* Padding */
.p-0 { padding: 0px !important; }
.p-5 { padding: 5px; }
.p-8 { padding: 8px; }
.p-16 { padding: 16px !important; }
.p-10 { padding: 10px !important; }
.p-l-5 { padding: 5px; }
.p-l-10 { padding-left: 10px; }
.p-r-10 { padding-right: 10px; }
.p-l-15 { padding-left: 15px; }
.p-r-15 { padding-right: 15px; }
.p-b-15 { padding-bottom: 15px; }
.p-l-20 { padding-left: 20px; }

/* Font size */
.fs-12 { font-size: 12px; }
.fs-13 { font-size: 13px; }
.fs-16 { font-size: 16px; }
.fs-20 { font-size: 20px; }
.fs-25 { font-size: 25px; }
.fs-30 { font-size: 30px;}

/* Font weight */
.fw-500 { font-weight: 500;}
.fw-700 { font-weight: 700;}
.fw-bold { font-weight: bold;}

/* Color */
.gray { color: gray !important; }
.white { color: white !important; }
.dark-blue { color: darkblue !important;}
.red { color: red !important;}
.black { color: black !important;}

/* gap */
.g-3 { gap: 3px !important; }
.g-5 { gap: 5px !important; }


/* text align */
.align-right { text-align: right !important;}
.text-center { text-align: center !important;}

.table-header { 
  color: black;
  /* font-family:'Times New Roman', Times, serif; */
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 18px;
  font-weight: 700 !important;
  background-color: aliceblue;
  padding: 10 !important;
}

.cursor { cursor: pointer !important; }


.d-flex { display: flex; }
.justify-center { justify-content: center;}
.justify-around { justify-content: space-around;}
.justify-between { justify-content: space-between;}
.justify-start { justify-content: start;}
.justify-end { justify-content: end;}
.align-center { align-items: center; }
.flex-direction-row { flex-direction: row;}
.flex-direction-column { flex-direction: column;}
.flex-direction-custom { flex-direction: row;}
.inline { display: inline; }
.flex-wrap { flex-wrap: wrap !important;}

a {
  text-decoration: none;
}

.main-container {
  display: flex;
}

.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

/* Sidebar */
.sidebar {
  color: white;
  min-height: 100vh;
  border-right: 1px solid lightgray;
}

.top_section {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #b34d00;
}
.logo {
  font-size: 18px;
  line-height: 0;
}
.bars {
  width: 30px;
}
.hide {
  display: none;
}

.search {
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 30px;
  padding: 10px;
}
.search input {
  border: none;
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(238, 238, 238);
  color: white;
}

.routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.link {
  display: flex;
  color: rgb(2, 2, 77);
  gap: 10px;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.link:hover {
  font-weight: bold;
  background-color: rgb(2, 2, 77);
  opacity: 0.9;
  color: white;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.active {
  border-right: 2px solid rgb(2, 2, 77);
  font-weight: bold;
  color: rgb(2, 2, 77);
  background-color: lightgray;
}

.link_text {
  white-space: nowrap;
  font-size: 15px;
  text-decoration: none !important;
}

.menu {
  display: flex;
  color: white;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}

.sticky {
  position: -webkit-sticky;
  position: sticky !important;
  top: 0;
  z-index: 1001;
}

/* ---------TAg Grid Style----------- */

.header-border {
  border-left: 1px solid lightgray;
}

.ag-root-wrapper {
  background-color: #f0f0f0;
  border-radius: 5px;
}

/* ------------------------------------- */
